import {Button, Divider, Header, Icon, Modal, Segment, Table} from "semantic-ui-react";
import moment from "moment";
import _ from "lodash";
import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import { beautifyFileName, beautifyFileDate } from "../../utils/Methods"
import { DELETE_ICON, DOWNLOAD_ICON, EYE_ICON, TAG_ICON } from "../../utils/UiIcons";
import { DEVICE_ID_HEADER, DEVICE_MODEL_HEADER, END_DATE_LABEL, FAILED_ERROR_MESSAGE, FULLNAME_HEADER, NOTHING_HERE_MESSAGE, REPORT_ID_HEADER, SESSION_ID_HEADER, START_DATE_LABEL, testSessionDetailMessages, TYPE_LABEL, USERNAME_HEADER } from "../../utils/UIMessages";
import { PrivilegeConstants } from "../../utils/PrivilegeConstants";
import { showError } from "../../utils/ToastHelpers";
import { downloadReportFile } from "../../api/apiCalls";
import MediaViewer from "../../components/MediaViewer";

const username = localStorage.getItem('username');
class TestSessionDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mediaLoading: false,
            mediaViewerVisibility: false,
            mediaSrc: null,
            isMediaImage: false,
            isMediaVideo: false
        }
    }

    downloadFilePrivilegeCheck = () => {
        if (this.props.testSessionFields.type == 'MANUAL') {
            return !(this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.DOWNLOAD_ANY) ||
                this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT) ||
                username === this.props.testSessionFields.username);
        } else {
            return !(this.props.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.DOWNLOAD_ANY) ||
                this.props.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.OVERALL_MANAGEMENT)||
                username === this.props.testSessionFields.username);
        }
    }

    deleteFilePrivilegeCheck = () => {
        if (this.props.testSessionFields.type == 'MANUAL') {
            return !(this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.DELETE_FILE) ||
                this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.DELETE_ANY_FILE) ||
                this.props.privileges.includes(PrivilegeConstants.MANUAL_TEST_SESSIONS.OVERALL_MANAGEMENT));
        } else {
            return !(this.props.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.DELETE_FILE) ||
                this.props.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.DELETE_ANY_FILE) ||
                this.props.privileges.includes(PrivilegeConstants.AUTOMATION_TEST_SESSIONS.OVERALL_MANAGEMENT));
        }
    }

    handleMedia = (file, download) => {
        const { testSessionFields } = this.props;

        let fileParam = null;
        if (file !== undefined || file !== 'all') {
            fileParam = `?file=${file}`;
        }
        this.setState({ mediaViewerVisibility: true, mediaLoading: true });

        downloadReportFile(testSessionFields.id, fileParam).then(res => {
            if (file.startsWith('video')) {
                this.setState({ isMediaVideo: true });
            } else if (file.startsWith('screenshot')) {
                this.setState({ isMediaImage: true });
            }

            const url = window.URL.createObjectURL(new Blob([res.data]));

            this.setState({ mediaLoading: false });

            if (download) {
                const link = document.createElement('a');
                const fileAttribute = file ?? `${testSessionFields.id}.zip`;
                link.href = url;
                link.setAttribute(
                    'download',
                    fileAttribute
                );
                document.body.appendChild(link);
                link.click();
                link.remove();
                this.setState({ mediaViewerVisibility: false, mediaLoading: false });
            } else {
                this.setState({ mediaSrc: url })
            }

        }).catch(err => {
            showError(
                FAILED_ERROR_MESSAGE() + ': ' +
                this.props.t(err?.response?.data?.message ?? err.toString())
            );
            this.setState({ mediaViewerVisibility: false, mediaLoading: false });
        })
    }

    render() {
        let {testSessionFields} = this.props;
        return (
            <>
            <Modal
                open={this.props.modalVisibility} onClose={this.props.handleModalClose} closeOnDimmerClick={this.props.handleModalClose} closeIcon
                size={"small"}>
                <Modal.Content>
                    <Divider horizontal>
                        <Header as='h4'>
                            <Icon name={TAG_ICON}/>
                            {testSessionDetailMessages().TEST_SESSION_INFO_LABEL}
                        </Header>
                    </Divider>
                    <Table celled singleLine>
                        <Table.Body>
                            {testSessionFields.sessionId &&
                                <Table.Row>
                                    <Table.Cell><b>{REPORT_ID_HEADER()}</b></Table.Cell>
                                    <Table.Cell>{testSessionFields.id}</Table.Cell>
                                </Table.Row>}
                            <Table.Row>
                                <Table.Cell><b>{testSessionDetailMessages().TEST_SESSION_DETAIL_LABEL}</b></Table.Cell>
                                <Table.Cell className='test-session-info-modal-td'>
                                    <Table>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>
                                                {SESSION_ID_HEADER()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.sessionId || testSessionFields.id}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {TYPE_LABEL()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.type}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {START_DATE_LABEL()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {moment(testSessionFields.startDate).format("DD/MM/YYYY HH:mm.ss")}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {END_DATE_LABEL()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {moment(testSessionFields.endDate).format("DD/MM/YYYY HH:mm.ss")}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>{testSessionDetailMessages().USER_DETAIL_LABEL}</b></Table.Cell>
                                <Table.Cell className='test-session-info-modal-td'>
                                    <Table>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>
                                                {USERNAME_HEADER()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.username}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {FULLNAME_HEADER()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.fullName}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>{testSessionDetailMessages().DEVICE_DETAIL_LABEL}</b></Table.Cell>
                                <Table.Cell className='test-session-info-modal-td'>
                                    <Table>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>
                                                {DEVICE_ID_HEADER()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.udId}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {DEVICE_MODEL_HEADER()}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.deviceModel}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {testSessionDetailMessages().DEVICE_BRAND_LABEL}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.deviceBrand}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {testSessionDetailMessages().DEVICE_OS_LABEL}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.deviceOs}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                {testSessionDetailMessages().DEVICE_OS_VERSION_LABEL}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {testSessionFields.deviceOsVersion}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><b>{testSessionDetailMessages().TEST_SESSION_FILES_LABEL}</b></Table.Cell>
                                <Table.Cell className='test-session-info-modal-td'>
                                    <Table>
                                        <Table.Body>
                                            {!_.isEmpty(testSessionFields.fileNames) ?
                                                Object.keys(testSessionFields.fileNames).map((key, i) => {
                                                    const file = testSessionFields.fileNames[key];
                                                    return <>
                                                        <Table.Row key={i}>
                                                            <Table.Cell>
                                                                {beautifyFileName(file)} {beautifyFileDate(file)}
                                                            </Table.Cell>
                                                            <Table.Cell style={{ 'white-space': 'nowrap' }}>
                                                            <div className='ui three buttons'>
                                                                    <Button color='green' size='tiny' basic
                                                                        onClick={() => this.handleMedia(file, true)}
                                                                        disabled={this.downloadFilePrivilegeCheck()}
                                                                        icon={DOWNLOAD_ICON} />
                                                                    <Button color='blue' size='tiny' basic
                                                                        onClick={() => this.handleMedia(file, false)}
                                                                        disabled={this.downloadFilePrivilegeCheck() || (!file.startsWith('video') && !file.startsWith('screenshot'))}
                                                                        icon={EYE_ICON} />
                                                                    <Button color='red' size='tiny' basic
                                                                        onClick={() => this.props.deleteFile(file)}
                                                                        disabled={this.deleteFilePrivilegeCheck()}
                                                                        icon={DELETE_ICON} />
                                                                </div>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </>
                                                })
                                                : <><Table.Row>
                                                    <Segment placeholder attached={"top"}>
                                                        <Header icon>
                                                            <Icon name='folder open outline'/>
                                                            {NOTHING_HERE_MESSAGE()}
                                                        </Header>
                                                    </Segment>
                                                </Table.Row>
                                                </>
                                            }
                                            {!_.isEmpty(testSessionFields.fileNames) ? <Table.Row>
                                                <Table.Cell>{testSessionDetailMessages().ZIP_FILE_LABEL}</Table.Cell>
                                                <Table.Cell><Button fluid color='green'
                                                                    onClick={() => this.props.downloadFile('all', true)}
                                                                    disabled={this.downloadFilePrivilegeCheck()}> <Icon
                                                    name={DOWNLOAD_ICON}/>{testSessionDetailMessages().ALL_FILES_DOWNLOAD_BUTTON}</Button>
                                                </Table.Cell>
                                            </Table.Row> : <></>}
                                        </Table.Body>
                                    </Table>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Modal.Content>
            </Modal>
            <Modal
                className='media-viewer' 
                basic
                open={this.state.mediaViewerVisibility}
                onClose={()=> {
                    this.setState({mediaViewerVisibility: false, isMediaImage: false, isMediaVideo: false})
                }}
                closeOnEscape={true}
                centered={true}
                size='mini'>

            <Modal.Content>
                <Segment basic loading={this.state.mediaLoading} padded>
                    <MediaViewer isVideo={this.state.isMediaVideo} isImage={this.state.isMediaImage} src={this.state.mediaSrc}/>
                </Segment>
            </Modal.Content>
          </Modal>
          </>
        );
    }
}

export default withTranslation()(TestSessionDetailsModal);